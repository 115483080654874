import {
  getBookmarksFromLocalStorage,
  handleBookmarkClick,
  handleRemoveBookmark,
} from "./BooksUtil";
import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";
import book_missing from "../../Assets/icons/missing-book.png";
import { useEffect, useState } from "react";

const BookDetailComponent = ({ book, onClose, isBookmarked, orderBook }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [isBookmarkedVar, setIsBookmarkedvar] = useState(isBookmarked);

  // Fetch bookmarks only once when the component mounts
  useEffect(() => {
    getBookmarksFromLocalStorage(setBookmarks);
  }, []); // Dependency array ensures this runs only on mount

  // Check if the current book is bookmarked whenever `bookmarks` updates
  useEffect(() => {
    if (bookmarks.some((b) => b.id === book.id)) {
      setIsBookmarkedvar(true);
    } else {
      setIsBookmarkedvar(false);
    }
  }, [bookmarks, book.id]);

  // Close the modal when clicked outside
  const handleClickOutside = (event) => {
    if (event.target.dataset.close) {
      onClose();
    }
  };

  if (!book) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      data-close="true"
      onClick={handleClickOutside}
    >
      <div
        className="fixed inset-y-0 right-0 bg-white shadow-xl w-[568px] h-full rounded-l-lg"
        onClick={(e) => e.stopPropagation()} // Prevent event from propagating to the outer div
      >
        <div className="h-full flex flex-col w-full">
          {/* Header */}
          <div className="flex flex-row w-full h-84 px-[33px] items-center py-[27px] shadow-md">
            <div className="text-2xl font-semibold text-customBlue line-clamp-1 w-11/12 flex justify-start">
              {book.title}
            </div>
            <div className="w-1/12 flex justify-end">
              <button
                className="text-xl sm:text-2xl font-bold text-gray-600 hover:text-gray-800"
                onClick={onClose}
              >
                &times;
              </button>
            </div>
          </div>
          {/* Author */}
          <div className="flex flex-row h-[56px] border-b mx-[33px] items-center">
            <div className="text-md text-customBlue line-clamp-1 w-11/12 flex justify-start">
              By {book.author}
            </div>
            <div className="w-1/12 flex justify-end">
              {isBookmarkedVar ? (
                <i
                  className="fa-solid fa-bookmark text-base sm:text-lg md:text-xl text-customBlue cursor-pointer"
                  onClick={() => {
                    handleRemoveBookmark(book, setBookmarks);
                    setIsBookmarkedvar(false);
                  }}
                />
              ) : (
                <i
                  className="fa-solid fa-bookmark text-base sm:text-lg md:text-xl text-gray-400 cursor-pointer"
                  onClick={() => {
                    handleBookmarkClick(book);
                    setIsBookmarkedvar(true);
                  }}
                />
              )}
            </div>
          </div>
          {/* Image */}
          <div className="w-full flex items-center justify-center">
            {book.image_url ? (
              <img
                src={book.image_url}
                alt={book.title}
                className="w-[205px] h-[303px] object-cover rounded-lg my-6"
              />
            ) : (
              <div className="w-[205px] h-[303px] flex justify-center items-center">
                <img
                  src={book_missing}
                  alt={book.title}
                  className="w-[80px] h-[80px] object-cover my-6"
                />
              </div>
            )}
          </div>

          {/* Description */}
          <div className="flex flex-grow overflow-y-auto text-base px-[33px]">
            {book.description}
          </div>
          {/* Divider */}
          <div className="w-full h-[1px] bg-gray-200 shadow"></div>
          {/* Footer */}
          <div className="flex items-end justify-end w-full h-[44px] my-[33px] px-[33px]">
            <button
              onClick={(event) => {
                event.stopPropagation();
                orderBook({ book, onClose });
              }}
              className="flex flex-row items-center w-[149px] bg-customSkyBlue hover:bg-customBlue justify-center hover:text-white text-customBlue h-11 rounded-lg"
            >
              <OpenBook width="19" height="19" fill="currentColor" />
              <span className="ml-2">Rent Now</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDetailComponent;
