import { getBookmarksFromLocalStorage, handleBookmarkClick, handleRemoveBookmark } from "./BooksUtil";
import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";
import book_missing from "../../Assets/icons/missing-book.png";
import { useEffect, useState } from "react";

const MbBookDetailComponent = ({
  book,
  onClose,
  orderBook,
  isBookmarked
}) => {
  const [bookmarks, setBookmarks] = useState([]);
    const [isBookmarkedVar, setIsBookmarkedvar] = useState(isBookmarked);
      // Fetch bookmarks only once when the component mounts
      // eslint-disable-next-line no-undef
      useEffect(() => {
        getBookmarksFromLocalStorage(setBookmarks);
      }, []); // Dependency array ensures this runs only on mount
    
      // Check if the current book is bookmarked whenever `bookmarks` updates
      useEffect(() => {
        if (bookmarks.some((b) => b.id === book.id)) {
          setIsBookmarkedvar(true);
        } else {
          setIsBookmarkedvar(false);
        }
      }, [bookmarks, book.id]); // Add `book.id` as a dependency to avoid stale references
    
  if (!book) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="fixed bottom-0  flex-col bg-white   z-50 w-full   h-10/12 min-h-[10/12] max-h-[11/12]   rounded-t-2xl ">
        {/* Header */}
        <div className="flex flex-row min-h-[56px]   shadow-sm  items-center  mx-[33px]  ">
          <div className="text-lg font-semibold text-customBlue line-clamp-1 w-11/12  flex justify-start my-[22px] ">
            {book.title}
          </div>
          <div className="w-1/12 flex justify-end">
            <button
              className=" text-2xl font-bold text-gray-600 hover:text-gray-800"
              onClick={onClose}
            >
              &times;
            </button>
          </div>
        </div>
        {/* Header 1 */}
        <div className="flex flex-row h-[56px] border-b  mx-[33px]  items-center ">
          <div className="text-sm  text-customBlue line-clamp-1 w-11/12  flex justify-start">
            By {book.author}
          </div>
          <div className="w-1/12 flex justify-end">
          {isBookmarkedVar ? (
                <i
                  className="fa-solid fa-bookmark text-base sm:text-lg md:text-xl text-customBlue cursor-pointer"
                  onClick={() => {
                    handleRemoveBookmark(book, setBookmarks);
                    setIsBookmarkedvar(false);
                  }}
                />
              ) : (
                <i
                  className="fa-solid fa-bookmark text-base sm:text-lg md:text-xl text-gray-400 cursor-pointer "
                  onClick={() => {
                    handleBookmarkClick(book);
                    setIsBookmarkedvar(true);
                  }}
                />
              )}
          </div>
        </div>
        {/* Image */}
        <div className="w-full flex items-center justify-center">
          {book.image_url ? (
            <>
              <img
                src={book.image_url}
                alt={book.title}
                className="w-[90px] h-[137px] object-cover  my-6 "
              />
            </>
          ) : (
            <>
              <div className="w-[90px] h-[137px] object-cover  my-6">
                <img
                  src={book_missing}
                  alt={book.title}
                  className=" w-[80px] h-[80px] object-cover  my-6 "
                />
              </div>
            </>
          )}
        </div>

        <div className="w-full px-[33px] mt-[16px] mb-[46px] h-[216px] overflow-y-scroll">
          {book.description || "No description available."}
        </div>
        {/* Divider */}
        <div className="w-full h-[1px] bg-gray-200 shadow"></div>
        <div className="w-full px-[16px] my-[16px]  ">
          <button
            onClick={() => orderBook({ book, onClose })}
            className="  flex flex-row items-center w-full  px-[16px] bg-customBlue justify-center text-white h-11 rounded-lg"
          >
            <OpenBook width="19" height="19" fill="currentColor" />
            <span className="ml-2">Rent Now</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MbBookDetailComponent;
