import Logo1 from "../../Assets/logo-1.webp";

const MbTitleBar = () => {
  return (
    <div className="flex  w-full mt-[24px] mb-2 ">
      <div className="px-[24px] h-[44px]  w-full flex flex-row justify-around ">
        <div className="w-1/2 flex justify-start">
          <a href="/">
            <img src={Logo1} alt="Logo" className="w-[42px] h-[42px]" />
          </a>
        </div>
        <div className=" w-1/2 flex justify-end">
          <div className="w-[42px] h-[42px] justify-center items-center flex border-2 rounded-full">
            <a href="/profile">
              <i className="fa-solid fa-user text-2xl text-customBlue" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MbTitleBar;
