import {
  CommentModel,
  CommentRequest,
  PostModel,
  PostRequest,
} from "../Serializers/SerializerModels";
import {
  fetchDeleteData,
  fetchGetData,
  fetchPostData,
} from "../Utilities/FetchUtils";

export const getPosts = async ({
  limit,
  offset,
  setPosts,
  setLimit,
  setOffset,
  setIsLoading,
}) => {
  setIsLoading(true);
  const { response, error } = await fetchGetData(
    `community?limit=${limit}&offset=${offset}`
  );
  if (response) {
    const posts = response.map((post) => new PostModel(post));
    setPosts(posts);
    setOffset(offset + limit);
    setIsLoading(false);
  }
};

export const likePost = async (id) => {
  const { response, error } = await fetchGetData(`community/like/${id}`);
  return response;
};

export const dislikePost = async (id) => {
  const { response, error } = await fetchGetData(`community/dislike/${id}`);
  return response;
};

export const deletePost = async (id) => {
  try {
    const { response, error } = await fetchDeleteData(`community/delete/${id}`);
  } catch (e) {
    console.log(e);
  } finally {
    window.location.reload();
  }
};

export const addPost = async ({ title, content }) => {
  const requestBody = new PostRequest({ title, post: content });
  const { response, error } = await fetchPostData(
    "community/create",
    requestBody
  );
  return new PostModel(response);
};

export const getComments = async ({ postId, setComments, offset,setOffset, setIsCommentLoading }) => {
  const { response, error } = await fetchGetData(
    `community/comment/?post_id=${postId}&offset=${offset}`
  );
  if (response) {
    setComments(response.map((post) => new CommentModel(post)));
    setOffset(offset + 10);
    setIsCommentLoading(false);
  }
};

export const likeComment = async (id) => {
  const { response, error } = await fetchGetData(
    `community/comment/like?id=${id}`
  );
  return response;
};

export const dislikeComment = async (id) => {
  const { response, error } = await fetchGetData(
    `community/comment/dislike?id=${id}`
  );
  return response;
};

export const deleteComment = async (id) => {
  const { response, error } = await fetchDeleteData(
    `community/comment/delete/${id}`
  );
  return response;
};

export const addComment = async ({ postId, comment }) => {
  const requestBody = new CommentRequest({ post_id: postId, comment });
  const { response, error } = await fetchPostData(
    "community/comment/create",
    requestBody
  );
  return new CommentModel(response);
};

export const getTimestamp = (timestamp) => {
  const now = new Date();
  const timest = new Date(timestamp);

  // Calculate the difference in milliseconds
  const timeDiff = now.getTime() - timest.getTime();

  // Convert time difference to hours and days
  const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  if (hoursDiff < 24) {
    return hoursDiff === 1 ? "1 hr ago" : `${hoursDiff} hrs ago`;
  } else if (daysDiff < 7) {
    return daysDiff === 1 ? "1 day ago" : `${daysDiff} days ago`;
  } else {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return timest.toLocaleDateString("en-US", options);
  }
};

