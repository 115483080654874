import React, { useEffect, useMemo, useState } from "react";
import { cancelOrder, getMyBooks, returnOrder } from "./MyBooksUtils";
import Loading from "../Common/Loading";
import { CancelOrderRequest } from "../Serializers/SerializerModels";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns"; // date formatting
import MbMyBooks from "./MbMyBooks";
import DkMyBooks from "./DkMyBooks";

const MyBooksComponent = ({ setSelectedTab }) => {
  const [myBooks, setMyBooks] = useState([]);
  const in_process = ["Dispatched", "To Be Delivered"];
  const books_with_me = ["Delivered", "To Be Picked Up"];
  const recent_order = ["Cancelled", "Picked Up", "Returned"];

  const categorizedBooks = useMemo(() => {
    if (!myBooks || myBooks.length === 0)
      return { in_process: [], books_with_me: [], recent_order: [] };

    // Categorize books
    return myBooks.reduce(
      (acc, book) => {
        const { order_status } = book;
        if (in_process.includes(order_status)) {
          acc.in_process.push(book);
        } else if (books_with_me.includes(order_status)) {
          acc.books_with_me.push(book);
        } else if (recent_order.includes(order_status)) {
          acc.recent_order.push(book);
        }

        return acc;
      },
      { in_process: [], books_with_me: [], recent_order: [] } // Initial structure
    );
  }, [myBooks]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setSelectedTab("books");
    getMyBooks({ setMyBooks, setIsLoading, setError });
  }, []);



  const handleCancelOrder = (book) => {
    setIsLoading(true);
    cancelOrder(new CancelOrderRequest(book.order_id), toast);
    setTimeout(() => {
      getMyBooks({ setMyBooks, setIsLoading, setError });
    }, 2000);
  };

  const handleReturnOrder = (book) => {
    setIsLoading(true);
    returnOrder(new CancelOrderRequest(book.order_id), toast);
    setTimeout(() => {
      getMyBooks({ setMyBooks, setIsLoading, setError });
    }, 2000);
  };

  const formatDate = (dateString) =>
    format(new Date(dateString), "dd MMM yyyy");

  return (
    <>
      <div className="flex md:hidden flex-col w-full h-full ">
        <MbMyBooks
          categorizedBooks={categorizedBooks}
          isLoading={isLoading}
          handleReturnOrder={handleReturnOrder}
          handleCancelOrder={handleCancelOrder}
        />
      </div>
      <div className="hidden md:flex flex-col w-full h-full">
        <DkMyBooks
          categorizedBooks={categorizedBooks}
          isLoading={isLoading}
          handleReturnOrder={handleReturnOrder}
          handleCancelOrder={handleCancelOrder}
        />
      </div>
    </>
  );
};

export default MyBooksComponent;
