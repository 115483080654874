import {
  SubscribeRequest,
  Subscriptions,
  UserObject,
  UserSubscription,
} from "../Serializers/SerializerModels";
import { fetchGetData, fetchPostData } from "../Utilities/FetchUtils";
import { load } from "@cashfreepayments/cashfree-js";
export const getCurrentUser = async ({ setUserInfo, setError }) => {
  const { response } = await fetchGetData("user/get_current_user");
  if (response) {
    setUserInfo(new UserObject(response));
  } else {
    setError("Errot occured while fetching user. Please try again");
  }
};

export const getSubscriptionDetails = async ({
  setSubscriptionDetail,
  setIsLoading,
}) => {
  setIsLoading(true);
  const { response } = await fetchGetData("subscription/my_subscription");
  if (response) {
    if (response.length !== 0) {
      setSubscriptionDetail(new UserSubscription(response[0]));
    } else {
      setSubscriptionDetail(null);
    }
  } else {
  }
  setIsLoading(false);
};

export const handleLogout = async () => {
  localStorage.clear();
  window.location.replace("/login");
};

export const changePassword = async ({
  changePasswordRequest,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const { response, error } = await fetchPostData(
    "user/change_password",
    changePasswordRequest
  );
  if (response) {
    setSuccessMessage(true);
  } else {
    setErrorMessage("Current Password is incorrect. Please try again");
  }
};

export const copyToClipboard = async (text) => {
  if (navigator.clipboard) {
    // Modern async clipboard API
    try {
      await navigator.clipboard.writeText(text);
      alert("Invite code copied!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  } else {
    // Fallback for older browsers
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      alert("Invite code copied!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
    document.body.removeChild(textArea);
  }
};

export const getSubscriptions = async (setSubscriptionDetail) => {
  const { response } = await fetchGetData("subscription/get_all_plans");
  if (response) {
    const subscriptions = await response.map((data) => new Subscriptions(data));

    setSubscriptionDetail(
      subscriptions.sort((a, b) => a.priority - b.priority)
    );
  } else {
    setSubscriptionDetail([]);
  }
};

export const userSubscription = async (plan) => {
  const { response } = await fetchPostData(
    "subscription/subscribe",
    new SubscribeRequest(plan.subscription_id)
  );
  if (response) {
    initiatePayment(response);
  }
};

export const initiatePayment = async (session_id) => {
  const cashfree = await load({
    mode: "sandbox",
  });
  let checkoutOptions = {
    paymentSessionId: session_id,
    redirectTarget: "_self", //optional ( _self, _blank, or _top)
  };

  cashfree.checkout(checkoutOptions);
};

export const user_subscribe = async ({ subscription_id,setSubscriptionSuccess,setSubscriptionError }) => {
  try {
    const { response } = await fetchPostData(
      "subscription/subscribe",
      new SubscribeRequest(subscription_id)
    );

    if (response && response.short_url) {
      // Open the payment page in a new tab
      // window.open(response.short_url, "_blank");
      triggerCheckout(response,setSubscriptionSuccess,setSubscriptionError);
    } else {
      console.error("Error: Missing short_url in the response");
    }
  } catch (error) {
    console.error("Error during subscription:", error);
  }
};

export const cancelUserSubscription = async ({ toast }) => {
  const { response, error } = await fetchPostData("subscription/cancel");
  if (response) {
    toast.success("Subscription cancelled successfully");
  } else {
    console.log(error);
    toast.error("Please return all the books to cancel the subscription.");
  }
  setTimeout(() => {
    window.location.reload();
  }, 5000);
};

export const triggerCheckout = (response,setSubscriptionSuccess,setSubscriptionError) => {
  const options = {
    key: "rzp_live_lN3stCuUYlhZ51", // Replace with your Razorpay Key ID
    subscription_id: response.id,
    name: "Readkar", // Your brand name
    description: "Subscription Payment",
    theme: {
      color: "#001F3F", // Customize the checkout theme color
    },
    handler: (response) => {
      // Handle successful payment
      setSubscriptionSuccess(true)
      setTimeout(() => {
        window.location("/home");
      }, 5000);

      // Send response to backend for verification
      // verifyPayment(response);
    },
    modal: {
      ondismiss: () => {
        setSubscriptionError(true)
        setTimeout(() => {
          window.location("/home");
        }, 5000);
      },
    },
  };

  const rzp = new window.Razorpay(options);
  rzp.open();
};


