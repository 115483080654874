import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import { GoogleButton } from "../Utilities/GoogleSSOButtons";
import sideImage from "../../Assets/illustration.webp";
import { DividerWithText } from "../Common/UtilsComponent";
import { handlePasswordChange, handleUsernameChange } from "./LoginUtils";
import { verifyLogin } from "./AuthUtils";
import { handleLogin } from "./LoginUtils";
import { UserContext } from "../../App";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {setIsUserLoggedIn} = useContext(UserContext);

  useEffect(() => {
    const userLoggedIn = async () => {
      try {
        setIsLoading(true);
        const loggedIn = await verifyLogin();
        if (loggedIn) {
          navigate("/home");
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    userLoggedIn();
  }, []);

  return (
    <div className="flex flex-col items-center h-[100%] bg-gray-100 justify-center">
      {isLoading ? (
        <></>
      ) : (
        <div className="flex flex-row items-center">
          <div className="w-full max-w-xs px-6 py-8 bg-white overflow-hidden sm:max-w-sm rounded-lg shadow-lg border-1">
            <p className="text-start text-4xl font-bold mb-6">Sign In</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin({
                  username,
                  password,
                  setErrorMessage,
                  navigate,
                  setIsUserLoggedIn
                });
              }}
            >
              <div className="mb-4">
                <CustomFloatingInput
                  required={true}
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => {
                    handleUsernameChange({ e, setUsername, setErrorMessage });
                  }}
                  text="Phone Number or Email"
                  icon="fa-solid fa-envelope"
                />
              </div>
              <div className="mb-6">
                <CustomFloatingInput
                  required={true}
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) =>
                    handlePasswordChange({ e, setPassword, setErrorMessage })
                  }
                  text="Password"
                  icon="fa-solid fa-lock"
                  togglePassword={() => setPasswordShown(!passwordShown)}
                />
              </div>
              {errorMessage && (
                <div className="mb-4 p-3 flex items-center text-sm text-red-600 bg-red-100 rounded-lg border border-red-200 animate-pulse">
                  <svg
                    className="w-5 h-5 mr-2 fill-current"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-12a1 1 0 11-1 1 1 1 0 011-1zm1 3H9v4h2V9z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="flex-1">{errorMessage}</span>
                </div>
              )}

              <div className="flex items-center flex-col">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Log In
                </button>
                <span
                  className="text-sm mt-4 text-blue-600 hover:text-blue-800"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </span>
              </div>
            </form>
            <DividerWithText text="OR" />
            <div className="flex flex-col md:flex-row  mb-4 justify-center mt-4">
              <div className="md:m-2 sm:m-1 w-full">
                <GoogleButton />
              </div>
            </div>
            <div className="mt-4 text-center">
              <p className="text-sm">
                Invited or have an invitation code?{" "}
                <a
                  href="/signup"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Signup here
                </a>
              </p>
            </div>
          </div>
          <div className="hidden md:block md:w-1/2">
            <img
              src={sideImage}
              alt="Descriptive Alt Text"
              className="object-cover max-w-[477px] "
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
