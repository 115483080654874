import { useContext, useEffect, useState } from "react";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import { copyToClipboard, getCurrentUser, handleLogout } from "./UserUtils";
import { useNavigate } from "react-router-dom";
import ChangePasswordComponent from "./ChangePassword";
import { UserContext } from "../../App";
import AddressSelection from "../Order/AddressComponent";
import { ToastContainer } from "react-toastify";
import AddAddressComponent from "../Order/AddAddressComponent";

const UserInfoComponent = ({ address, setShowAddaddressModal }) => {
  const [editable, setEditable] = useState(false);
  const [editedInfo, setEditedInfo] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [showChangePassword, setShowChangePassword] = useState(false);

  useEffect(() => {
    async function fetchData() {
      getCurrentUser({ setUserInfo, setError: () => {} });
    }
    fetchData();
  }, []);
  const handleChange = (e) => {
    // console.log(e);
    setEditedInfo({ ...editedInfo, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Profile updated!");
  };

  return (
    <>
      {showChangePassword ? (
        <>
          <ChangePasswordComponent
            setShowChangePassword={setShowChangePassword}
          />
        </>
      ) : (
        <>
          <div className="w-full md:flex hidden">
            {" "}
            <DkProfileInfo
              userInfo={userInfo}
              editable={editable}
              editedInfo={editedInfo}
              setEditable={setEditable}
              handleChange={handleChange}
              setEditedInfo={setEditedInfo}
              setShowAddaddressModal={setShowAddaddressModal}
              address={address}
            />
          </div>

          <div className="w-full md:hidden flex overflow-y-auto">
            {" "}
            <MbProfileInfo
              userInfo={userInfo}
              editable={editable}
              editedInfo={editedInfo}
              setEditable={setEditable}
              handleChange={handleChange}
              setEditedInfo={setEditedInfo}
              setShowAddaddressModal={setShowAddaddressModal}
              address={address}
            />
          </div>
        </>
      )}
    </>
  );
};
export default UserInfoComponent;

const DkProfileInfo = ({
  userInfo,
  editable,
  editedInfo,
  setEditable,
  handleChange,
  setEditedInfo,
  setShowAddaddressModal,
  address,
}) => {
  return (
    <>
      <div className="w-full justify-center items-center flex-col mt-8 px-[35px] overflow-y-auto">
        <div className="w-full h-[275px] bg-violet-600"></div>
        <div className="w-full flex justify-center items-center -translate-y-[64px] ">
          <div className="w-10/12  bg-[#F7F9FBDE] rounded-lg px-[50px] flex flex-row py-6 bg-opacity-85">
            {" "}
            {/* Profile Picture */}
            <div className="w-1/3 ">
              {" "}
              <div className="w-[150px] h-[150px] rounded-full bg-customYellow flex justify-center items-center">
                <div className="text-3xl font-bold flex text-customBlue">
                  {" "}
                  {/* {userInfo  ? userInfo.first_name[0] + userInfo.last_name[0] : ""} */}
                  <i className="fa-solid fa-user fa-xl"></i>
                </div>
              </div>{" "}
            </div>
            {/* Details */}
            <div className="w-2/3 flex flex-col">
              {/* Title */}
              <div className=" flex flex-row">
                <div className="w-1/2 flex justify-start items-center text-base font-semibold">
                  My Profile
                </div>
                <div className="w-1/2 flex justify-end items-center">
                  {" "}
                  {editable ? (
                    <>
                      {" "}
                      <div className="flex gap-4">
                        <div
                          className="h-[32px] w-[98px]  bg-[#FFE5E5] text-[#F64C4C] hover:bg-red-500  hover:text-white  rounded-lg flex items-center justify-center   text-xs font-semibold"
                          onClick={() => {
                            setEditedInfo(null);
                            setEditable(false);
                          }}
                        >
                          {" "}
                          Cancel
                        </div>
                        <div
                          className="h-[32px] w-[98px]  bg-customSkyBlue hover:bg-customBlue  hover:text-white  rounded-lg flex items-center justify-center   text-xs font-semibold"
                          onClick={() => {
                            setEditedInfo(null);
                            setEditable(false);
                          }}
                        >
                          {" "}
                          Save
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="h-[32px] w-[98px]  bg-customSkyBlue hover:bg-customBlue  hover:text-white  rounded-lg flex items-center justify-center   text-xs font-semibold"
                        onClick={() => {
                          setEditedInfo(userInfo);
                          setEditable(true);
                        }}
                      >
                        {" "}
                        Edit
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Divider */}
              <div className="w-full h-[1px] bg-gray-200 mt-4"></div>

              {/* Details */}
              <div className=" flex flex-col mt-7 gap-7 w-full">
                <div className="flex flex-row gap-6 w-full">
                  <div className="flex flex-col w-1/2">
                    <div className="text-sm text-gray-500 font-semibold">
                      {" "}
                      First Name
                    </div>
                    <div>
                      <input
                        type="text"
                        id="first_name"
                        className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow ${
                          !editable ? "font-semibold" : ""
                        }`}
                        value={
                          editable ? editedInfo.first_name : userInfo.first_name
                        }
                        disabled={!editable}
                        onChange={handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div className="flex flex-col  w-1/2">
                    <div className="text-sm text-gray-500 font-semibold">
                      {" "}
                      Last Name
                    </div>
                    <div>
                      <input
                        type="text"
                        id="last_name"
                        className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow ${
                          !editable ? "font-semibold" : ""
                        }`}
                        value={
                          editable ? editedInfo.last_name : userInfo.last_name
                        }
                        disabled={!editable}
                        onChange={handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row gap-6">
                  <div className="flex flex-col w-1/2">
                    <div className="text-sm text-gray-500 font-semibold">
                      {" "}
                      Email
                    </div>
                    <div>
                      <input
                        type="text"
                        id="email"
                        className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow ${
                          !editable ? "font-semibold" : ""
                        }`}
                        value={editable ? editedInfo.email : userInfo.email}
                        disabled={!editable}
                        onChange={handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2">
                    <div className="text-sm text-gray-500 font-semibold">
                      {" "}
                      Phone No.
                    </div>
                    <div>
                      <input
                        type="text"
                        id="phone_number"
                        className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow ${
                          !editable ? "font-semibold" : ""
                        }`}
                        value={
                          editable
                            ? editedInfo.phone_number
                            : userInfo.phone_number
                        }
                        disabled={!editable}
                        onChange={handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="text-sm text-gray-500 font-semibold">
                    {" "}
                    Invite Code
                  </div>
                  <div>
                    <input
                      type="text"
                      id="phone_number"
                      className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow font-semibold`}
                      value={userInfo.invite_code}
                      disabled={true}
                      onChange={handleChange}
                    />{" "}
                  </div>
                </div>
                {/* Address */}
                <>
                  <div className=" flex flex-row">
                    <div className="w-1/2 flex justify-start items-center text-base font-semibold">
                      My Address
                    </div>
                    <div className="w-1/2 flex justify-end items-center">
                      {" "}
                      <button
                        onClick={() => setShowAddaddressModal(true)}
                        className="  w-[120px] flex flex-row items-center   gap-2  bg-customSkyBlue justify-center text-customBlue h-11 rounded-lg px-2"
                      >
                        <i className="fa fa-plus border border-customBlue text-[12px] p-[1px] rounded w-4 h-4" />
                        <span className="text-xs">Add Address</span>
                      </button>
                    </div>
                  </div>

                  <div className="w-full h-[1px] bg-gray-200 mt-4"></div>
                  <div className="w-full flex flex-col gap-4">
                    {address.length > 0 ? (
                      address.map((addr, index) => (
                        <>
                          <div
                            key={index}
                            className="flex flex-col  border  bg-white px-3 py-[18px]  rounded-lg text-gray-600 hover:border-customYellow hover:text-customBlue "
                          >
                            <div className="flex flex-row">
                              <div className="flex items-center justify-center">
                                {" "}
                                <i className=" fa-solid fa-location-dot text-2xl me-3" />
                              </div>
                              <div className="  flex-col   flex justify-start">
                                <div className="text-base  font-semibold   line-clamp-1">
                                  {addr.name}
                                </div>
                                <div className="text-sm  line-clamp-1">
                                  {addr.phoneNumber}
                                </div>
                              </div>
                            </div>

                            <div className="h-[1px] w-full bg-gray-300 my-[10px]"></div>
                            {/* Address Details */}
                            <div className="text-sm">
                              {addr.addressLine1} {addr.addressLine2}{" "}
                              {addr.city} {addr.state} {addr.pincode}{" "}
                              {addr.country}
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              </div>
              <div className="w-full flex justify-end ">
                <button
                  onClick={() => {
                    handleLogout();
                  }}
                  className="bg-red-700 text-white px-4 py-2 rounded-lg mt-4 font-semibold text-sm"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

const MbProfileInfo = ({
  userInfo,
  editable,
  editedInfo,
  setEditable,
  handleChange,
  setEditedInfo,
  setShowAddaddressModal,
  address,
}) => {
  return (
    <>
      <div className="w-full justify-center items-center flex-col mt-8  overflow-y-auto">
        <div className="  rounded-lg  flex flex-col py-6 bg-opacity-85 px-5 justify-center items-center overflow-y-auto">
          {" "}
          {/* Profile Picture */}{" "}
          <div className="w-[150px] h-[150px] rounded-full bg-customYellow flex justify-center items-center">
            <div className="text-3xl font-bold flex text-customBlue">
              {" "}
              <i className="fa-solid fa-user fa-xl"></i>
            </div>
          </div>{" "}
          {/* Details */}
          <div className="w-full flex flex-col mt-4">
            {/* Title */}
            <div className=" flex flex-row">
              <div className="w-1/2 flex justify-start items-center text-base font-semibold">
                My Profile
              </div>
              <div className="w-1/2 flex justify-end items-center">
                {" "}
                {editable ? (
                  <>
                    {" "}
                    <div className="flex gap-4">
                      <div
                        className="h-[32px] w-[98px]  bg-[#FFE5E5] text-[#F64C4C] hover:bg-red-500  hover:text-white  rounded-lg flex items-center justify-center   text-xs font-semibold"
                        onClick={() => {
                          setEditedInfo(null);
                          setEditable(false);
                        }}
                      >
                        {" "}
                        Cancel
                      </div>
                      <div
                        className="h-[32px] w-[98px]  bg-customSkyBlue hover:bg-customBlue  hover:text-white  rounded-lg flex items-center justify-center   text-xs font-semibold"
                        onClick={() => {
                          setEditedInfo(null);
                          setEditable(false);
                        }}
                      >
                        {" "}
                        Save
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="h-[32px] w-[98px]  bg-customSkyBlue hover:bg-customBlue  hover:text-white  rounded-lg flex items-center justify-center   text-xs font-semibold"
                      onClick={() => {
                        setEditedInfo(userInfo);
                        setEditable(true);
                      }}
                    >
                      {" "}
                      Edit
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Divider */}
            <div className="w-full h-[1px] bg-gray-200 mt-4"></div>

            {/* Details */}
            <div className=" flex flex-col mt-7 gap-7 w-full">
              <div className="flex flex-row gap-6 w-full">
                <div className="flex flex-col w-1/2">
                  <div className="text-sm text-gray-500 font-semibold">
                    {" "}
                    First Name
                  </div>
                  <div>
                    <input
                      type="text"
                      id="first_name"
                      className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow ${
                        !editable ? "font-semibold" : ""
                      }`}
                      value={
                        editable ? editedInfo.first_name : userInfo.first_name
                      }
                      disabled={!editable}
                      onChange={handleChange}
                    />{" "}
                  </div>
                </div>
                <div className="flex flex-col  w-1/2">
                  <div className="text-sm text-gray-500 font-semibold">
                    {" "}
                    Last Name
                  </div>
                  <div>
                    <input
                      type="text"
                      id="last_name"
                      className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow ${
                        !editable ? "font-semibold" : ""
                      }`}
                      value={
                        editable ? editedInfo.last_name : userInfo.last_name
                      }
                      disabled={!editable}
                      onChange={handleChange}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-6">
                <div className="flex flex-col w-1/2">
                  <div className="text-sm text-gray-500 font-semibold">
                    {" "}
                    Email
                  </div>
                  <div>
                    <input
                      type="text"
                      id="email"
                      className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow ${
                        !editable ? "font-semibold" : ""
                      }`}
                      value={editable ? editedInfo.email : userInfo.email}
                      disabled={!editable}
                      onChange={handleChange}
                    />{" "}
                  </div>
                </div>
                <div className="flex flex-col w-1/2">
                  <div className="text-sm text-gray-500 font-semibold">
                    {" "}
                    Phone No.
                  </div>
                  <div>
                    <input
                      type="text"
                      id="phone_number"
                      className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow ${
                        !editable ? "font-semibold" : ""
                      }`}
                      value={
                        editable
                          ? editedInfo.phone_number
                          : userInfo.phone_number
                      }
                      disabled={!editable}
                      onChange={handleChange}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col">
                <div className="text-sm text-gray-500 font-semibold">
                  {" "}
                  Invite Code
                </div>
                <div>
                  <input
                    type="text"
                    id="phone_number"
                    className={`w-full mt-3 p-3 rounded-lg border text-xs focus:border-customYellow font-semibold`}
                    value={userInfo.invite_code}
                    disabled={true}
                    onChange={handleChange}
                  />{" "}
                </div>
              </div>
              {/* Address */}
              <>
                <div className=" flex flex-row">
                  <div className="w-1/2 flex justify-start items-center text-base font-semibold">
                    My Address
                  </div>
                  <div className="w-1/2 flex justify-end items-center">
                    {" "}
                    <button
                      onClick={() => setShowAddaddressModal(true)}
                      className="  w-[120px] flex flex-row items-center   gap-2  bg-customSkyBlue justify-center text-customBlue h-11 rounded-lg px-2"
                    >
                      <i className="fa fa-plus border border-customBlue text-[12px] p-[1px] rounded w-4 h-4" />
                      <span className="text-xs">Add Address</span>
                    </button>
                  </div>
                </div>

                <div className="w-full h-[1px] bg-gray-200 "></div>
                <div className="w-full flex flex-col gap-4 ">
                  {address.length > 0 ? (
                    address.map((addr, index) => (
                      <>
                        <div
                          key={index}
                          className="flex flex-col  border  bg-white px-3 py-[18px]  rounded-lg text-gray-600 hover:border-customYellow hover:text-customBlue "
                        >
                          <div className="flex flex-row">
                            <div className="flex items-center justify-center">
                              {" "}
                              <i className=" fa-solid fa-location-dot text-2xl me-3" />
                            </div>
                            <div className="  flex-col   flex justify-start">
                              <div className="text-base  font-semibold   line-clamp-1">
                                {addr.name}
                              </div>
                              <div className="text-sm  line-clamp-1">
                                {addr.phoneNumber}
                              </div>
                            </div>
                          </div>

                          <div className="h-[1px] w-full bg-gray-300 my-[10px]"></div>
                          {/* Address Details */}
                          <div className="text-sm">
                            {addr.addressLine1} {addr.addressLine2} {addr.city}{" "}
                            {addr.state} {addr.pincode} {addr.country}
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </>
              <div className="w-full flex justify-end  mb-20">
                <button
                  onClick={() => {
                    handleLogout();
                  }}
                  className="bg-red-700 text-white px-4 py-2 rounded-lg mt-4 font-semibold text-sm"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
